<template>
  <div class="sp-product-item-wrapper fs-13 fc-555 fb-500">
    <a
      class="product-img-wrapper"
      :href="`/product/${productInfo.code}_${$Utils.replaceSpacesWithDash(
        productInfo.nameEn
      )}`"
    >
      <v-img
        class="w-100 mb-2 img"
        :aspect-ratio="1"
        v-if="productInfo.imgMainUrl"
        :src="productInfo.imgMainUrl + '?x-oss-process=image/resize,l_300'"
        lazy-src="/imgs/lazyimgplaceholder.webp"
      ></v-img>
    </a>
    <a
      class="pointer mt-4"
      :href="`/product/${productInfo.code}_${$Utils.replaceSpacesWithDash(
        productInfo.nameEn
      )}`"
    >
      <p v-bind="props" class="fc-555 fs-14 name" :title="productInfo.nameEn">
        {{ replaceName(productInfo.nameEn) }}
      </p>
    </a>
    <p class="fb-400 my-1"># {{ productInfo.code }}</p>
    <div class="price">
      <span>Low As&nbsp;&nbsp;</span>
      <span class="fs-16 fb-650 fc-black-09">${{ productInfo.price }}</span>
    </div>
  </div>
</template>

<script setup>
const { $Utils } = useNuxtApp();
const config = useRuntimeConfig();
const primaryColor = config.public.primaryColor;
// 定义props productInfo，类型是对象，默认值是{}
const props = defineProps({
  productInfo: {
    type: Object,
    required: true
  }
});

const replaceName = str => {
  return str.replace(/\s+/g, " ");
};
/**
 * 跳转到产品详情
 * @param {info} 产品数据
 */
const goDetail = info => {
  const path = `${info.code}_${$Utils.replaceSpacesWithDash(info.nameEn)}`;

  navigateTo(`/product/${path}`);
};
</script>

<style lang="scss" scoped>
.sp-product-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .name {
    width: 100%;
    height: 30px;
    line-height: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
  .product-img-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ededed;
    border-radius: 4px;
    &:hover {
      transform: scale(1.035);
      border-color: v-bind(primaryColor);
    }
    img {
      position: absolute;
      border-radius: 4px;
      top: 50%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      transform: translateY(-50%);
    }
    .img {
      position: absolute;
      border-radius: 4px;
      top: 50%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      transform: translate3d(0, -50%, 0);
    }
  }
}

a:hover {
  text-decoration: underline;
  color: black;
}

p {
  font-size: 14px;
  color: #999;
}
.price {
  line-height: 1.2;
}
:deep(.v-img__img--preload) {
  filter: none;
}
</style>
